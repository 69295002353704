@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .page-title {
    @apply text-3xl font-bold mb-4 pt-4;
  }
  .btn-primary-md {
    @apply px-4 py-2 bg-primary text-white font-semibold rounded-lg hover:bg-primary-light transition duration-200;
  }
  .sidebar {
    @apply h-full w-64 fixed top-0 left-0 bg-dark text-white;
  }
  .sidebar-item {
    @apply text-primary text-xl mb-0.5 hover:text-primary-light transition duration-200;
  }
  .navbar {
    @apply w-full h-16 fixed top-0 left-0 bg-dark text-white z-10;
  }
  .nav-item {
    @apply flex flex-col items-center;
  }
  .nav-dropdown {
    @apply absolute w-2/5 top-14 right-0 bg-dark shadow-lg rounded-bl-md;
  }
  .form-input {
    @apply bg-white mb-4 px-4 py-2 w-full rounded-xl transition focus:outline-0 focus:shadow-lg focus:shadow-primary-light duration-300;
  }
  .form-input-inactive {
    @apply mb-4 px-4 py-2 w-full rounded-xl transition focus:outline-0 focus:shadow-lg focus:shadow-primary-light duration-300;
  }
  .tag {
    @apply rounded-lg py-0.5 px-1.5 text-primary hover:text-white hover:bg-primary text-xs mt-1 mr-2 transition duration-200;
  }
  .card-badge {
    @apply absolute px-2 py-1 rounded-full bg-primary text-sm text-white shadow-md font-bold;
  }
  .control-num {
    @apply bg-gray-200 hover:bg-gray-100 text-gray-600 h-8 w-8 px-2 pt-1 transition duration-200;
  }
  .control-num-disabled {
    @apply bg-primary-light text-white h-8 w-8 px-2 pt-1;
  }
  .control-btn {
    @apply bg-primary hover:bg-primary-light transition duration-200 text-white h-8 px-3;
  }
  .control-btn-disabled {
    @apply bg-primary-light text-white h-8 px-3;
  }
}

html {
  font-family: "Nunito", sans-serif;
  background-color: rgb(241, 241, 241);
}

.react-pdf__Page {
  margin: 0 auto;
  max-width: 95vw !important;
  max-height: 134vw !important;
  overflow: hidden;
  border-radius: 2%;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  max-width: 95vw !important;
  max-height: 134vw !important;
  border-radius: 2%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-pdf__Page__textContent {
  margin: 0 auto;
  max-width: 95vw !important;
  max-height: 134vw !important;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.page-controls {
  position: absolute;
  bottom: -3.5%;
  left: 0;
}

.bg-dark {
  background-color: rgb(54, 52, 52);
}

.card-badge {
  top: -16px;
  left: -10px;
}

.loader {
  border: 16px solid #e6e6e6; /* Light grey */
  border-top: 16px solid #ff7f50; /* Orange */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1s linear infinite;
}

.loader-desktop {
  position: absolute;
  top: 35%;
  left: 52%;
}

.loader-mobile {
  position: absolute;
  top: 40%;
  left: 35%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
